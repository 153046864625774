import React from 'react';
import './App.css';

function App() {
  return (
      <>
          <div className="white"><h3 className="text">popping.pl</h3></div>
          <div className="red"><span className="info">strona w budowie</span></div>
      </>
  );
}

export default App;
